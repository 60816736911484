import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faDownload } from "@fortawesome/free-solid-svg-icons";

// import { Document } from "react-pdf";

import "./modelos-dictamen.scss";
import useResizer from "../utils/useResizer";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";

export default function ModelosDictamen() {
    const isMobile = useResizer();

    return (
      <React.Fragment>
        <SEO title="Modelos de dictamen" />
        <Layout pageId="dictamen-models">
          <div className="modelos-dictamen-container">
            <h1>Modelos de Dictamen</h1>
            {/* <Document file="/docs   /DICTAMEN-JURIDICO-ok.pdf" /> */}
            {/* <object
                        data="/docs/DICTAMEN-JURIDICO-ok.pdf"
                        type="application/pdf"
                        width="700px"
                        height="700px"
                    >
                        <embed src="/docs/DICTAMEN-JURIDICO-ok.pdf">
                            <p>
                                This browser does not support PDFs. Please
                                download the PDF to view it:{" "}
                                <a href="/docs/DICTAMEN-JURIDICO-ok.pdf">
                                    Download PDF
                                </a>
                                .
                            </p>
                        </embed>
                    </object> */}

            {/* {!isMobile && (
                        <section className="grid grid--desktop-1cols grid--smartphone-1cols">
                            <div className="dictamen-container">
                                <h2>Dictamen CON viabilidad</h2>
                                <iframe src="/docs/DICTAMEN-JURIDICO-OK.pdf" />
                            </div>
                            <div className="dictamen-container">
                                <h2>Dictamen SIN viabilidad</h2>
                                <iframe src="/docs/DICTAMEN-JURIDICO-KO.pdf" />
                            </div>
                        </section>
                    )}
                    {isMobile && (
                        <ul className="modelos-dictamen-mobile">
                            <li>
                                <a href="/docs/DICTAMEN-JURIDICO-OK.pdf" className="button">
                                    Dictamen CON viabilidad
                                    <FontAwesomeIcon icon={faDownload} />

                                </a>
                            </li>
                            <li>
                                <a href="/docs/DICTAMEN-JURIDICO-KO.pdf" className="button">
                                    Dictamen SIN viabilidad
                                    <FontAwesomeIcon icon={faDownload} />

                                </a>
                            </li>
                        </ul>
                    )} */}

            <Accordion allowZeroExpanded={true}>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Modelo de Dictamen CON Viabilidad
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <img
                    src="/docs/DICTAMEN-JURIDICO-OK-1.png"
                    alt="negligencias de abogados, dictamen juridico ok 1"
                  />
                  <hr></hr>
                  <img
                    src="/docs/DICTAMEN-JURIDICO-OK-2.png"
                    alt="negligencias de abogados, dictamen juridico ok 2"
                  />
                  <hr></hr>
                  <img
                    src="/docs/DICTAMEN-JURIDICO-OK-3.png"
                    alt="negligencias de abogados, dictamen juridico ok 3"
                  />
                  <hr></hr>
                  <img
                    src="/docs/DICTAMEN-JURIDICO-OK-4.png"
                    alt="negligencias de abogados, dictamen juridico ok 4"
                  />
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Modelo de Dictamen SIN Viabilidad
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <img
                    src="/docs/DICTAMEN-JURIDICO-KO-1.png"
                    alt="negligencias de abogados, dictamen juridico ko 1"
                  />
                  <hr></hr>
                  <img
                    src="/docs/DICTAMEN-JURIDICO-KO-2.png"
                    alt="negligencias de abogados, dictamen juridico ko 2"
                  />
                  <hr></hr>
                  <img
                    src="/docs/DICTAMEN-JURIDICO-KO-3.png"
                    alt="negligencias de abogados, dictamen juridico ko 3"
                  />
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </Layout>
      </React.Fragment>
    )
}
